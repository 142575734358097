import './newsfeed.css'
import {useState} from "react";
import Feed from "./feed";

const Newsfeeds = ({stories}) => {

    const [allFeeds, setAllFeeds] = useState([
        {name: "NFL", url: "https://www.espn.com/espn/rss/nfl/news", icon: "logo-nfl.svg"},
        {name: "MLB", url: "https://www.espn.com/espn/rss/mlb/news", icon: "logo-mlb.svg"},
        {name: "NBA", url: "https://www.espn.com/espn/rss/nba/news", icon: "logo-nba.svg"},
        {name: "NHL", url: "https://www.espn.com/espn/rss/nhl/news", icon: "logo-nhl.svg"},
    ])

    return (
        <div className="row feeds">
            {allFeeds.map((feed) => (
                <div className="col-sm-3" id="sports">
                    <Feed feed={feed} key={feed.id}/>
                </div>
            ))}
        </div>
    )
}

export default Newsfeeds