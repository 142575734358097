import {Component} from "react";

class PageTitle extends Component {
    render() {
        return (
            <div className="centerFlexBox">
                <h1 className="sitetitle">
                    SportsKnot
                </h1>
            </div>
        )
    }
}

export default PageTitle;