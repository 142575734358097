import './main-page.css';
import {BrowserRouter as Router} from "react-router-dom";
import RSSParser from "rss-parser"
import HeadlineStory from "./headline-story";
import PageTitle from "./page-title";
import {useEffect, useMemo, useState} from "react";
import Newsfeeds from "../newsfeeds";

function Index() {
    const [allStories, setAllStories] = useState([]);

    // Load ESPN Top Stories RSS Feed
    useEffect(() => {
        const fetchStories = async () => {
            let parser = new RSSParser({
                customFields: {
                    //feed: ['otherTitle', 'extendedDescription'],
                    item: ['image'],
                }
            })

            await parser.parseURL("https://www.espn.com/espn/rss/news", function (err, feed) {
                if (err) throw err;
                setAllStories(feed.items)
            });
        }
        fetchStories();
    }, []);

    const featuredStory = useMemo(() => {
        if (allStories.length) {
            //const randomIndex = Math.floor(Math.random() * allStories.length)
            return allStories[0]
        }
    }, [allStories])

    return (
        <Router>
            <div className="container">
                <HeadlineStory
                    story={featuredStory}
                />
                <PageTitle/>
                <Newsfeeds stories={allStories}/>
                <footer></footer>
            </div>
        </Router>
    );
}

export default Index;
