import RSSParser from "rss-parser";
import {useEffect, useState} from "react";

const Feed = ({feed}) => {

    const [allStories, setAllStories] = useState([]);

    useEffect(() => {
        const fetchStories = async () => {
            let parser = new RSSParser({
                customFields: {
                    //feed: ['otherTitle', 'extendedDescription'],
                    item: ['image'],
                }
            })

            await parser.parseURL(feed.url, function (err, feed) {
                if (err) throw err;
                console.log(feed.items)
                setAllStories(feed.items)
            });
        }
        fetchStories();
    }, []);

    return (
        <div className="row mx-2">
            <img src={feed.icon} className="leagueIcon"/>
            {allStories.map((story) => (
                <a className="row story my-2 mx-2" key={story.id} href={story.link}>{story.title}</a>
            ))}
        </div>

    );
}

export default Feed;