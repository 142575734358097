import {Component} from "react";

class HeadlineStory extends Component {

    render() {
        const followLink = () => {
            window.open(this.props.story.link)
        };

        if(this.props.story) {
            return (
                <div className="centerFlexBox">
                    <div className="row">
                        <div className="col-sm-12">
                            <div>
                                <img src={this.props.story.enclosure.url}
                                     className="mainphoto"
                                     alt="Headline Image"
                                     onClick={followLink}
                                />
                            </div>
                            <div className="maintitle"
                                 onClick={followLink}>
                                {this.props.story.title}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div>No featured story at this time</div>;
        }
    }
}

export default HeadlineStory;